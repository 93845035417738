.colors-wrapper {
   display: flex;
   gap: 12px;
   flex-wrap: wrap;
   justify-content: center;

   .color-button-item {
      width: 35px;
      height: 35px;
      border-radius: 50%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid rgba(0, 0, 0, .2);
   }
}