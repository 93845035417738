@import '/src/App.scss';

.products-wrapper {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   margin-bottom: 30px;

   @include screen(small) {
      flex-wrap: nowrap;
      overflow-x: scroll;
      justify-content: flex-start;
   }

   @include screen(medium) {
      flex-wrap: nowrap;
      overflow-x: scroll;
      justify-content: flex-start;
   }

   .product-item {
      @include screen(small) {
         width: 50%;
      }

      @include screen(medium) {
         width: 50%;
      }
   }

   .product-link {
      text-decoration: none;
      color: inherit;
   }
}