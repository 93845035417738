@import '/src/App.scss';

.grid-link {
   text-decoration: none;
   color: inherit;

   .grid-wrapper {
      position: relative;
      border-radius: 20px;
      cursor: pointer;
      max-width: 100%;
      height: 320px;
      border-radius: 20px;
      background-size: cover;

      @include screen(small) {
         height: 190px;
      }

      @include screen(medium) {
         height: 190px;
         width: 80%;
         margin: 0 auto;
      }

      .grid-text {
         position: absolute;
         top: 30px;
         left: 40px;
         font-size: 36px;
         font-weight: 500;

         @include screen(small) {
            left: 20px;
            top: 20px;
            font-size: 24px;
         }

         @include screen(medium) {
            left: 20px;
            top: 20px;
            font-size: 24px;
         }
      }
   }
}