@import '/src/App.scss';

.brand-logos-stack {
   background-color: #000000;
   width: 100%;
   height: 100%;
   justify-content: space-evenly;
   align-items: center;
   gap: 10px;
   row-gap: 0;

   @include screen(small) {
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 40px;
      padding: 30px 0;
   }

   @include screen(medium) {
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      column-gap: 40px;
      padding: 30px 0;
   }
}