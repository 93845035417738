@import '/src/App.scss';

.top-toolbar {

   @include screen(small) {
      display: flex;
      justify-content: space-between;
   }

   @include screen(medium) {
      display: flex;
      justify-content: space-between;
   }

   .app-icon {
      text-transform: uppercase;
      font-size: 32px;
      font-weight: 800;
      color: #000000;
      cursor: pointer;
      text-decoration: none;
   }

   .header-links {
      display: flex;
      margin-left: 40px;
      flex-grow: 1;

      @include screen(small) {
         display: none;
      }

      @include screen(medium) {
         display: none;
      }
   }

   .search {
      position: relative;
      background-color: rgba(240, 240, 240, 1);
      border-radius: 62px;
      flex-grow: 1;

      &:hover {
         background-color: rgba(240, 240, 240, 0.5);
      }

      .search-icon-wrapper {
         position: absolute;
         display: flex;
         align-items: center;
         justify-content: center;
         padding-left: 15px;
         padding-top: 10px;

         .search-icon {
            color: rgba(0, 0, 0, .4);
         }
      }

      .search-input {
         padding: 6px 6px 6px 50px;
      }

      @include screen(small) {
         display: none;
      }

      @include screen(medium) {
         display: none;
      }
   }

   .header-actions {
      margin-left: 20px;
      display: flex;

      .responsive-search-icon {
         display: none;

         @include screen(small) {
            display: flex;
         }

         @include screen(medium) {
            display: flex;
         }
      }
   }

   .toolbar-icon {
      @include screen(large) {
         display: none;
      }

      @include screen(x-large) {
         display: none;
      }
   }
}