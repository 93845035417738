$homebanner: url('../../../assets/svg/homebannerrect.svg');
@import '/src/App.scss';

.banner-wrapper {
   display: flex;
   height: 100vh;

   @include screen(small) {
      flex-direction: column;
      height: 100%;
   }

   @include screen(medium) {
      flex-direction: column;
      height: 100%;
   }

   .banner-main {
      flex: 1;
      background-color: #F2F0F1;

      .banner-content {
         flex: 1;
         display: block;
         padding-top: 10%;
         padding-left: 16%;

         @include screen(small) {
            padding: 6% 4% 0 4%;
         }

         @include screen(medium) {
            padding: 6% 4% 0 4%;
         }

         .banner-title {
            display: block;
            font-size: 64px;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 1.1;
            max-width: 500px;
            user-select: none;
            margin-bottom: 15px;

            @include screen(small) {
               font-size: 36px;
            }

            @include screen(medium) {
               font-size: 36px;
            }
         }

         .banner-subtext {
            display: block;
            font-size: 16px;
            max-width: 550px;
            margin-top: 20px;
            margin-bottom: 30px;
         }

         .banner-details {
            justify-content: space-between;
            margin-top: 50px;

            @include screen(small) {
               flex-wrap: wrap;
               justify-content: center;
            }

            @include screen(medium) {
               flex-wrap: wrap;
               justify-content: center;
            }

            .banner-detail {
               display: grid;

               .banner-detail-text {
                  font-size: 40px;
                  font-weight: 600;
               }

               .banner-detail-subtext {
                  font-size: 16px;
                  opacity: 60%;
               }
            }
         }

         .banner-shop-now {
            width: 210px;

            @include screen(small) {
               margin: 0 auto;
               width: 100%;
            }

            @include screen(medium) {
               margin: 0 auto;
               width: 50%;
            }
         }
      }
   }

   .banner-image-wrapper {
      flex: 1;
      background-color: #F2F0F1;

      .banner-image {
         background-image: $homebanner;
         background-size: cover;
         background-position: center;
         height: 135vh;
         position: relative;
         display: flex;

         @include screen(small) {
            height: 500px;
         }

         @include screen(medium) {
            height: 500px;
         }

         .banner-star-left {
            flex: 1;
            margin-top: 30%;
            width: 56px;
            height: 56px;
         }

         .banner-star-right {
            flex: 1;
            margin-top: 10%;
            width: 104px;
            height: 104px;
         }
      }
   }
}