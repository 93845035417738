@import '/src/App.scss';

.product-details {
   .product-images-wrapper {
      @include screen(small) {
         flex-direction: column-reverse;
      }

      @include screen(medium) {
         flex-direction: column-reverse;
      }

      .product-images {
         .pdt-img {
            border-radius: 20px;
            cursor: pointer;
            width: 152px;
         }
      }

      .enlarged-image-wrapper {
         .pdt-img {
            width: 444px;
            border-radius: 20px;
         }
      }
   }

   .product-details-info {
      .product-ratings {
         display: flex;
         gap: 8px;
      }

      .available-colors {
         display: flex;
         flex-direction: column;
         gap: 10px;
         width: fit-content;
      }

      .available-sizes {
         display: flex;
         flex-direction: column;
         gap: 10px;
      }

      .product-price {
         display: flex;
         gap: 10px;
         font-size: 24px;
         font-weight: 600;
         align-items: flex-end;
         margin: 10px 0;

         .previous-price {
            color: #9A9A9A;
         }

         .discount-price {
            background: rgba(255, 51, 51, .1);
            border: none;
            width: 58px;
            height: 28px;
         }
      }

      .product-title {
         font-size: 40px;
         font-weight: 800;
         text-transform: uppercase;
      }

      .product-description {
         color: rgba(0, 0, 0, .6);
      }

      .product-actions {
         display: flex;
         align-items: center;
         gap: 20px;

         .cart-button-wrapper {
            width: 400px;
         }
      }

      .product-divider {
         margin: 18px 0;
      }
   }
}

.tabs-section {
   margin-top: 60px;
}

.suggestion-section {
   display: flex;
   flex-direction: column;
   gap: 40px;

   .section-title {
      display: flex;
      justify-content: center;
      text-transform: uppercase;
      font-size: 48px;
      font-weight: bold;

      @include screen(small) {
         font-size: 32px;
      }

      @include screen(medium) {
         font-size: 32px;
      }
   }
}