@import '/src/App.scss';

.container {
   .home-section {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .section-title {
         display: flex;
         justify-content: center;
         text-transform: uppercase;
         font-size: 48px;
         font-weight: bold;

         @include screen(small) {
            font-size: 32px;
            text-align: center;
         }

         @include screen(medium) {
            font-size: 32px;
            text-align: center;
         }
      }

      .button-view-wrapper {
         display: flex;
         justify-content: center;
         width: 100%;

         .button-view-all {
            width: 210px;
         }
      }
   }

   .divider {
      margin: 50px 0 50px 0;
   }

   .browse-dress-style {
      background-color: #F0F0F0;
      border-radius: 40px;
      padding: 50px;
   }

   .testimonials-carousel {
      height: 500px;
      margin-top: 80px;

      .testimony-wrapper {
         display: flex;
         justify-content: space-between;
         align-items: center;
      }
   }

   .top-selling {
      margin-bottom: 50px;
   }
}

.home-section.home-brands {
   background-color: #000000;
   width: 100%;
   min-height: 120px;
   display: grid;
   margin-bottom: 50px;

   @include screen(small) {
      margin-bottom: 100px;
   }

   @include screen(medium) {
      margin-bottom: 100px;
   }
}

.home-section.home-banner {
   overflow: hidden;
}