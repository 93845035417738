@import '/src/App.scss';

.cart-wrapper {
   margin-bottom: 50px;

   .cart-title {
      text-transform: uppercase;
      font-weight: 800;
      font-size: 40px;
   }

   .cart-contents {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 20px;

      @include screen(small) {
         flex-direction: column;
      }

      @include screen(medium) {
         flex-direction: column;
      }

      .cart-content {
         border: 1px solid rgba(0, 0, 0, .1);
         border-radius: 20px;
         padding: 0 20px;
      }

      .cart-items-wrapper {
         flex: 1.5;
         display: flex;
         flex-direction: column;

         .cart-item-wrapper {
            .cart-item {
               display: flex;
               gap: 20px;
               padding: 24px 0;

               .cart-item-image {}

               .cart-item-content {
                  flex: 2;
                  display: flex;
                  flex-direction: column;

                  .item-title-remove {
                     display: flex;
                     justify-content: space-between;

                     .item-title {
                        font-size: 20px;
                        font-weight: 600;
                     }
                  }

                  .item-price-count {
                     display: flex;
                     justify-content: space-between;
                     align-items: end;

                     .item-price {
                        font-size: 24px;
                        font-weight: 600;
                     }
                  }
               }

               .cart-item-image .image {
                  border-radius: 20px;
                  width: 124px;
               }
            }
         }
      }

      .cart-order-summary-wrapper {
         flex: 1;
         height: fit-content;
         padding: 20px 24px;

         .order-summary-title {
            font-size: 24px;
            font-weight: 600;
            margin-bottom: 10px;
         }

         .order-summary-item {
            display: flex;
            justify-content: space-between;

            .summary-key {
               font-size: 20px;
               color: rgba(0, 0, 0, .6);
            }

            .summary-values {
               font-size: 20px;
               font-weight: 700;
            }

            .discount {
               color: #FF3333;
            }

            .total {
               font-size: 20px;
               color: #000000;
            }

            .total-value {
               font-size: 24px;
            }

         }

         .promo-code-wrapper {
            display: flex;
            gap: 16px;

            .promo-code-text {
               background-color: #F0F0F0;
               color: #000000;
               border-radius: 62px;
               width: 350px;
               height: 42px;
               padding-left: 16px;
               flex: 2;
            }

            .promo-button-wrapper {
               flex: 1;
            }
         }

         .summary-item {
            padding: 12px 0;
         }
      }
   }

   .cart-empty-container {
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .cart-message-header {
         display: flex;
         justify-content: center;
         font-size: 20px;
         font-weight: 600;
      }

      .cart-message-subtext {
         display: flex;
         justify-content: center;
      }
   }
}