@import '/src/App.scss';

.footer-wrapper {
   height: auto;
   margin-top: 150px;
   background-color: #F0F0F0;
   position: relative;
   display: flex;
   justify-content: center;

   @include screen(small) {
      margin-top: 200px;
   }

   @include screen(medium) {
      margin-top: 200px;
   }

   .newsletter-container {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 200px;
      background-color: #000000;
      border-radius: 20px;
      padding: 36px 64px;
      display: flex;
      align-items: center;

      @include screen(small) {
         flex-direction: column;
         max-width: 90%;
         padding: 36px;
         height: auto;
      }

      @include screen(medium) {
         flex-direction: column;
         max-width: 90%;
         padding: 36px;
         height: auto;
      }

      .newsletter-banner {
         text-transform: uppercase;
         color: #FFFFFF;
         font-weight: 800;
         font-size: 40px;
         flex: 1;

         @include screen(small) {
            font-size: 32px;
         }

         @include screen(medium) {
            font-size: 32px;
         }
      }

      .newsletter-form {
         flex: 1;
         display: flex;
         flex-direction: column;
         gap: 10px;
         align-items: flex-end;

         @include screen(small) {
            width: 100%;
            align-items: center;
            margin-top: 32px;
         }

         @include screen(medium) {
            width: 100%;
            align-items: center;
            margin-top: 32px;
         }

         .newsletter-email {
            background-color: #FFFFFF;
            color: #000000;
            border-radius: 62px;
            width: 350px;
            height: 42px;
            padding-left: 16px;

            @include screen(small) {
               width: 100%;
            }

            @include screen(medium) {
               width: 80%;
            }
         }

         .button-subscribe {
            width: 350px;

            @include screen(small) {
               width: 100%;
            }

            @include screen(medium) {
               width: 80%;
            }
         }
      }
   }

   .footer-contents-wrapper {
      display: flex;
      flex-direction: column;
      margin-top: 150px;
      margin-bottom: 100px;
      gap: 20px;

      @include screen(small) {
         margin-top: 50%;
      }

      @include screen(medium) {
         margin-top: 25%;
      }

      .footer-contents {
         display: flex;
         justify-content: space-between;
         gap: 20px;

         @include screen(small) {
            flex-direction: column;
         }

         @include screen(medium) {
            flex-direction: column;
         }

         .footer-links-wrapper {
            display: flex;
            flex: 2;

            @include screen(small) {
               display: grid;
               grid-template-columns: repeat(auto-fill, minmax(46%, 1fr));
               gap: 16px;
            }

            @include screen(medium) {
               display: grid;
               grid-template-columns: repeat(auto-fill, minmax(46%, 1fr));
               gap: 16px;
            }

            .footer-column {
               display: flex;
               flex-direction: column;
               flex: 1;
               gap: 20px;

               .footer-link {
                  text-decoration: none;
                  color: rgba(0, 0, 0, .6);
                  cursor: pointer;
               }

               .footer-link-title {
                  text-transform: uppercase;
                  font-weight: 600;
               }
            }
         }

         .footer-column-logo {
            display: flex;
            flex-direction: column;
            gap: 10px;
            flex: 1;

            .app-icon {
               text-transform: uppercase;
               font-size: 32px;
               font-weight: 800;
               color: #000000;
               text-decoration: none;
            }

            .logo-text {
               width: 75%;
               color: rgba(0, 0, 0, .6);

               @include screen(small) {
                  width: 100%;
               }

               @include screen(medium) {
                  width: 100%;
               }
            }

            .social-links {
               display: flex;
               gap: 10px;

               .social-link {
                  cursor: pointer;
               }
            }
         }

      }

      .footer-end {
         display: flex;
         justify-content: space-between;

         @include screen(small) {
            align-items: center;
            flex-direction: column;
            gap: 10px;
         }

         @include screen(medium) {
            align-items: center;
            flex-direction: column;
            gap: 10px;
         }

         .copyright {
            color: rgba(0, 0, 0, .6);
         }

         .payment-options {
            display: flex;
            gap: 10px;

            .vendor {
               padding: 6px 10px;
               background-color: #FFFFFF;
               border-radius: 6px;
            }
         }
      }
   }
}